import React, { useMemo } from 'react';
import { parseDate } from '../utils/parseDate';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  value: any;
}

const DateInput = (props: Props) => {
  const { value, ...rest } = props;
  const parsedValue = useMemo(() => {
    /**
     * If the user starts entering a new year we get a value such as 0002-01-01
     * This gets parsed to 2-01-01 which is not a valid date.
     * To prevent this we check the length of the year part of the date
     * If it is less than 4 we return the value as is
     */
    const parsedValue = parseDate(value) || '';
    const parsedYearLength = parsedValue.split('-')?.[0]?.length || 0;
    if (parsedYearLength < 4) return value;
    return parsedValue;
  }, [value]);

  return <input {...rest} type="date" value={parsedValue} />;
};

export default DateInput;
