import React, { ChangeEventHandler } from 'react';
import styled from 'styled-components';
import { colors } from '../utils/siteVars';

const StyledSwitch = styled.div<{ $allBlue?: boolean }>`
  position: relative;
  .toggle {
    position: relative;
    display: block;
    width: 40px;
    height: 20px;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    transform: translate3d(0, 0, 0);
  }
  .toggle:before {
    content: '';
    position: relative;
    top: 3px;
    left: 3px;
    width: 34px;
    height: 14px;
    display: block;
    background: ${({ $allBlue }) =>
      $allBlue ? colors.lightBlueFaded : '#9a9999'};
    border-radius: 8px;
    transition: background 0.2s ease;
  }
  .toggle span {
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    display: block;
    background: ${({ $allBlue }) => ($allBlue ? colors.lightBlue : 'white')};
    border-radius: 10px;
    box-shadow: 0 3px 8px rgba(154, 153, 153, 0.5);
    transition: all 0.2s ease;
  }
  .toggle span:before {
    content: '';
    position: absolute;
    display: block;
    margin: -18px;
    width: 56px;
    height: 56px;
    background: rgba(79, 46, 220, 0.5);
    border-radius: 50%;
    transform: scale(0);
    opacity: 1;
    pointer-events: none;
  }
  display: flex;
  input {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    pointer-events: none;
  }
  input:checked + .toggle:before {
    background: ${colors.lightBlueFaded};
  }
  input:checked + .toggle span {
    background: ${colors.lightBlue};
    transform: translateX(20px);
    transition:
      all 0.2s cubic-bezier(0.8, 0.4, 0.3, 1.25),
      background 0.15s ease;
    box-shadow: 0 3px 8px rgba(79, 46, 220, 0.2);
  }
  input:checked + .toggle span:before {
    transform: scale(1);
    opacity: 0;
    transition: all 0.4s ease;
  }
  p {
    font-size: 1rem !important;
    margin-right: 1rem !important;
    &.second-placeholder {
      margin-right: 0rem !important;
      margin-left: 1rem !important;
    }
  }
  margin-bottom: 0.8rem;
`;

type Props = {
  allBlue?: boolean;
  placeholder?: string;
  name: string;
  checked?: boolean;
  required?: boolean;
  onChange: ChangeEventHandler<HTMLInputElement>;
  secondPlaceholder?: string;
};

const Switch = (props: Props) => {
  return (
    <StyledSwitch $allBlue={props.allBlue}>
      <p>{props.placeholder}</p>

      <input
        type="checkbox"
        name={props.name}
        id={props.name}
        checked={props.checked}
        required={props.required}
        onChange={props.onChange}
      />
      <label htmlFor={props.name} className="toggle">
        <span></span>
      </label>
      {props.secondPlaceholder ? (
        <p className="second-placeholder">{props.secondPlaceholder}</p>
      ) : null}
    </StyledSwitch>
  );
};

export default Switch;
