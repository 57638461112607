import {
  Alert,
  FormOverlayState,
  GlobalState,
  PreventNavigationPopup,
} from '../types/state.types';

export const initialFormOverlay: FormOverlayState = {
  users: [],
  hidden: false,
  active: false,
  header: '',
  customer: null,
  formData: null,
  formType: null,
  handleSubmit: (_v: null) => {},
};

export const initialPreventNavigationPopup: PreventNavigationPopup = {
  active: false,
  destination: '/',
};

export const initialAlert: Alert = {
  active: false,
  content: '',
};

export const initialGlobalState: GlobalState = {
  token: '',
  tokenVerified: false,
  loading: true,
  loadingProgress: null,
  preventNavigation: false,
  preventNavigationPopup: initialPreventNavigationPopup,
  user: {},
  alert: initialAlert,
  formOverlay: initialFormOverlay,
};
