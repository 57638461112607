import { UserBM } from '@bm-js/h2o-shared';
import { FormOverlayState, PreventNavigationPopup } from './state.types';

export enum ActionType {
  TOKEN_VERIFIED = 'TOKEN_VERIFIED',
  TOKEN_DISCARDED = 'TOKEN_DISCARDED',
  SET_USER = 'SET_USER',
  LOADING = 'LOADING',
  LOADING_PROGRESS = 'LOADING_PROGRESS',
  ALERT = 'ALERT',
  CLOSE_ALERT = 'CLOSE_ALERT',
  SET_PREVENT_NAVIGATE = 'SET_PREVENT_NAVIGATE',
  SET_PREVENT_NAVIGATE_POPUP = 'SET_PREVENT_NAVIGATE_POPUP',
  LOGOUT = 'LOGOUT',
  SET_FORM_OVERLAY = 'SET_FORM_OVERLAY',
  HIDE_FORM_OVERLAY = 'HIDE_FORM_OVERLAY',
  SHOW_FORM_OVERLAY = 'SHOW_FORM_OVERLAY',
  CLOSE_FORM_OVERLAY = 'CLOSE_FORM_OVERLAY',
}

type ActionTokenVerified = {
  type: ActionType.TOKEN_VERIFIED;
  token: string;
  user: UserBM;
};

type ActionTokenDiscarded = {
  type: ActionType.TOKEN_DISCARDED;
};

type ActionSetUser = {
  type: ActionType.SET_USER;
  user: UserBM;
};

type ActionLoading = {
  type: ActionType.LOADING;
  value: boolean;
};

type ActionLoadingProgress = {
  type: ActionType.LOADING_PROGRESS;
  value: boolean;
  progress: number | null;
};

type ActionAlert = {
  type: ActionType.ALERT;
  content: string;
};

type ActionCloseAlert = {
  type: ActionType.CLOSE_ALERT;
};

type ActionSetPreventNavigate = {
  type: ActionType.SET_PREVENT_NAVIGATE;
  value: boolean;
};

type ActionSetPreventNavigatePopup = {
  type: ActionType.SET_PREVENT_NAVIGATE_POPUP;
  value: PreventNavigationPopup;
};

type ActionLogout = {
  type: ActionType.LOGOUT;
};

type ActionSetFormOverlay = {
  type: ActionType.SET_FORM_OVERLAY;
  data: FormOverlayState;
};

type ActionHideFormOverlay = {
  type: ActionType.HIDE_FORM_OVERLAY;
};

type ActionShowFormOverlay = {
  type: ActionType.SHOW_FORM_OVERLAY;
};

type ActionCloseFormOverlay = {
  type: ActionType.CLOSE_FORM_OVERLAY;
};

export type Action =
  | ActionTokenVerified
  | ActionTokenDiscarded
  | ActionSetUser
  | ActionLoading
  | ActionLoadingProgress
  | ActionAlert
  | ActionCloseAlert
  | ActionSetPreventNavigate
  | ActionSetPreventNavigatePopup
  | ActionLogout
  | ActionSetFormOverlay
  | ActionHideFormOverlay
  | ActionShowFormOverlay
  | ActionCloseFormOverlay;
