export const colors = {
  white: '#fff',
  black: '#000',
  lightBlue: '#0D6EF4',
  lighterBlue: '#95C1FF',
  lightBlueFaded: 'rgba(13, 110, 244, 0.7)',
  lightGrey: '#EDEDED',
  darkGrey: '#414141',
  mediumGrey: '#A8A8A8',
  red: '#e63232',
  redFaded: 'rgba(230,50,50,0.7)',
  yellowAlert: '#fff875',
  orangeAlert: '#fabe75',
  redAlert: '#ffb5b5',
  pdfBlue: '#0074BD',
  pdfGrey: '#ddd',
};

const USE_LOCAL_SERVER = process.env.GATSBY_USE_LOCAL_SERVER;

const get_do_url = () => {
  if (USE_LOCAL_SERVER) return 'http://localhost:3002/';
  return process.env.GATSBY_IS_DEVELOPMENT
    ? 'https://dev-h2o-api.bucketmedia.se/'
    : 'https://h2o-api-v2.bucketmedia.se/';
};

export const do_url = get_do_url();
