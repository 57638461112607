import styled from 'styled-components';
import { colors } from '../utils/siteVars';

const GlobalStyles = styled.div`
  .app-inner {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: ${colors.darkGrey};
    }

    h1 {
      font-size: 2.5rem;
      font-weight: 600;
      margin-top: 0;
      &.page-header {
        position: relative;
        margin-bottom: 1rem;
        &.has-extra-button {
          line-height: 80%;
        }
        &:after {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          content: '';
          height: 1px;
        }
      }
    }

    span.page-header-right {
      position: absolute;
      top: 2.5rem;
      right: 2rem;
      display: flex;
      align-items: center;
      button {
        margin-left: 1rem;
      }
      img {
        margin-right: 0.5rem;
        width: 30px;
      }
    }

    h2 {
    }
    h3 {
      margin: 0;
      font-size: 3.1rem;
      font-weight: 600;
    }
    h4 {
      font-size: 1.6rem;
      font-weight: 400;
      margin: 0;
    }
    h5 {
      margin: 0;
      font-size: 1.1rem;
      font-weight: 500;
    }
    h6 {
      margin: 0;
      font-size: 1.15rem;
      font-weight: 500;
    }

    p {
      margin: 0;
      strong {
        font-weight: 600;
        font-size: 1.1rem;
      }
      &.light {
        color: ${colors.mediumGrey};
      }
    }
    .page-container {
      max-width: 1000px;
      margin: 3rem auto;
      padding: 1rem;
    }
  }

  input,
  select,
  textarea {
    font-family: inherit;
    font-size: 1rem;
    margin-bottom: 0.8rem;
    padding: 0.2rem;
  }
  textarea {
    resize: none;
    width: 100%;
    min-height: 150px;
    padding: 0.5rem;
    border-radius: 10px;
    border: 1px solid transparent;
    background: ${colors.lightGrey};
    &:focus {
      outline: none;
      border-color: ${colors.lightBlue};
    }
  }

  input {
    width: 100%;
    border: none;
    border-radius: 10px;
    background: ${colors.lightGrey};
    font-size: 1rem;
    padding: 0.8rem;
    border: 1px solid transparent;
    &:focus {
      outline: none;
      border-color: ${colors.lightBlue};
    }
  }
  select {
    display: block;
    width: 100%;
    border: none;
    border-radius: 10px;
    padding: 0.5rem;
    background: ${colors.lightGrey};
    border: 1px solid transparent;
    &:focus {
      outline: none;
      border-color: ${colors.lightBlue};
    }
  }

  hr {
    background-color: ${colors.mediumGrey};
    border: none;
    height: 1px;
  }
  .customer-card-header {
    display: flex;
    flex-wrap: wrap-reverse;
    align-items: center;
    padding-bottom: calc(1rem + 4px);
    h3 {
      padding-right: 1rem;
      font-weight: 600;
      font-size: 1.5rem;
    }
    .customer-card-header-right-content {
      margin-left: auto;
    }
  }
  .single-customer-inner {
    display: flex;
    .single-customer-left-content {
      width: 60%;
      margin-right: 1.5rem;
    }
    .single-customer-right-content {
      width: calc(40% - 1.5rem);
    }
  }

  @media (max-width: 1000px) {
    .single-customer-inner {
      flex-direction: column;
      .single-customer-left-content {
        width: 100%;
      }
      .single-customer-right-content {
        width: 100%;
      }
    }
    .app-inner {
      h1 {
        font-size: 2rem;
      }
    }
  }
`;

export default GlobalStyles;
